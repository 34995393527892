import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import StyledHero from '../components/StyledHero'
import DocuImg from '../images/documentsneeded.jpg';
import Banner from '../components/Banner';
import {Helmet} from "react-helmet";



export default function Documents() {

    return (
        <div id="documentsWrappers">
            <Helmet>
                <title>Documentos</title>
                <meta name="description" content="LowRates US, Sun West documents needed." />
            </Helmet>
            <StyledHero img={DocuImg}>
                <Banner title="Documentos">
                </Banner>
            </StyledHero>            
            <Container>
                <Row>
                    <Col lg={12}>                                                                    
                        <div className="table-1 p-2 px-sm-0 pt-5">
                            

                                <Table striped bordered responsive="md">
                                <thead className="align-middle" style={{backgroundColor: "#78b1e2"}}>
                                    <tr className="centered">
                                        <th>Documentos</th>
                                        <th>Comprar</th>
                                        <th>Refinanciar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="tablebg">
                                        <td align="left" colSpan="3"><b>Documento de identidad</b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Copia de licencia de conducir</td>
                                        <td className="align-middle" align="center" rowSpan="2"><b>Sí</b></td>
                                        <td className="align-middle" align="center" rowSpan="2"><b>Sí</b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Tarjeta de Seguro Social</td>
                                    </tr>
                                    <tr className="tablebg">
                                        <td align="left" colSpan="3"><b>Documentación de Ingresos</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Asalariados</em></b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Dos talonarios recientes de pago (Nota: Uno de los talonarios debe ser de los últimos 30 días o máximo, no más de 5 semanas, partiendo del día en que se entregan los documentos).</td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>Sí</b></td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>Sí*</b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Planillas W2 y 1099 de los últimos dos años (si aplica).</td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Planillas de impuestos 1040 (declaración federal de impuestos personal) de los últimos dos años con documentos de respaldo.</td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Forma K-1 de los últimos dos años — si trabajas por cuenta propia puedes conseguir tus ingresos en el área de Schedule-E de la Declaración de Impuestos Personales.</td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Trabajo Por Cuenta Propia</em></b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Estado de situación de ganancias y pérdidas y estado de cuenta, si ha pasado más de un trimestre desde la fecha en que llenó las planillas del año fiscal más reciente – si aplica.</td>
                                        <td className="align-middle" align="center" rowSpan="3"><b>Sí</b></td>
                                        <td className="align-middle" align="center" rowSpan="3"><b>Sí*</b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Los últimos dos años de las planillas 1120S / 1120 / 1065 de la Declaración de Impuestos Federales para cualquier clase de corporación, con documentos de respaldo – si aplica.</td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Planilla K-1 de los últimos dos años con todos los documentos de respaldo – si aplica.</td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Otros Tipos de Ingresos</em></b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Cartas de aprobación de pensión recientes con prueba de 3 años de continuidad.</td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>Sí</b></td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>Sí*</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Cartas de aprobación de seguro social recientes.</td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Cartas de concesión por discapacidad recientes.</td>
                                    </tr>
                                    <tr>
                                        <td align="left">Copia del decreto final del divorcio, orden de manutención infantil e historia de pago del Departamento de Seguridad Económica (DES, por sus siglas en inglés).</td>
                                    </tr>
                                    <tr className="tablebg">
                                        <td align="left" colSpan="3"><b>Documentación de Activos Líquidos y No Líquidos</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">
                                        <li>Dos meses consecutivos de los estados de banco más recientes.</li> 
                                        <li>Sí la cuenta bancaria es compartida y el otro miembro de la cuenta no es solicitante, debes entregar una carta permitiendo acceso a los fondos (si aplicas al programa de préstamos).</li>
                                        </td>
                                        <td className="align-middle" align="center"><b>Sí</b></td>
                                        <td className="align-middle" align="center"><b>Sí*</b></td>
                                    </tr>
                                    <tr>
                                        <td>Documentación para usar dinero de regalo como pronto: <br/>
                                            <ul>
                                                <li>Una carta de regalo.</li>
                                                <li>Estado bancario del prestatario demostrando recibo del dinero de regalo.</li>
                                                <li>Estado bancario del donante demostrando transferencia del dinero.</li>
                                                <li>Los documentos de retiro que muestran que el retiro fue de la cuenta del donante.</li>
                                            </ul>
                                        </td>
                                        <td className="align-middle" align="center"><b>Sí</b></td>
                                        <td className="align-middle" align="center"><b>Sí*</b></td>
                                    </tr>
                                    <tr>
                                        <td>Cuentas de Retiro / Acciones y Bonos<br />
                                            <ul>
                                                <li>Los estados de los dos meses más recientes de acciones y bonos, o de la cuenta de retiro (si el estado es trimestral, provee el más reciente).</li>
                                                <li>Términos y condiciones de retiro que confirman el acceso suficiente para retirar fondos cuando sea necesario</li>
                                            </ul>
                                        </td>
                                        <td className="align-middle" align="center"><b>Sí</b></td>
                                        <td className="align-middle" align="center"><b>Sí*</b></td>
                                    </tr>
                                    <tr className="tablebg">
                                        <td align="left" colSpan="3"><b>Documentos específicos de la transacción</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Documentos adicionales a ser sometidos tras obtener disponibilidad para un préstamo hipotecario:</em></b></td>
                                    </tr>
                                    <tr>
                                        <td>Contrato de compra/venta u oferta de compra con todos los documentos (Nota: El contrato debe tener las firmas originales del comprador y vendedor).</td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>Sí</b></td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>No</b></td>
                                    </tr>
                                    <tr>
                                        <td>Copia del cheque y recibo de fianza de la compañía que provee el título/ fideicomiso.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Toda la información de contacto del agente de bienes raíces con el que estás trabajando, incluyendo su nombre, nombre de compañía, número de celular, correo electrónico y número de fax.</td>
                                    </tr>
                                    <tr>
                                        <td>Una vez disponible: Solicitud del seguro de la propiedad (Nota: Provee el nombre del agente, número de teléfono, número de fax y el correo electrónico).</td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Documentos adicionales a ser sometidos para préstamos de refinanciamiento:</em></b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Copia de nota de propiedad que quieres refinanciar.</td>
                                        <td className="align-middle" align="center"><b>Sí</b></td>
                                        <td className="align-middle" align="center"><b>Sí*</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Estado de hipoteca actual en préstamo siendo refinanciado.</td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>No</b></td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>Sí*</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Declaración del seguro de propiedad que refleje la prima anual.</td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Factura de impuestos para propiedad determinada</td>
                                    </tr>
                                    <tr>
                                        <td align="left">Factura actual de HOA (si aplica)</td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Documentos adicionales a ser sometidos si eres dueño de múltiples propiedades o rentas:</em></b></td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><ul>
                                            <li>Acuerdo(s) de renta de este año de todas las propiedades.</li>
                                            <li>Declaración del seguro de propiedad que refleje la prima anual de todas las propiedades.</li>
                                            <li>Facturas de impuestos de todas las propiedades.</li>
                                            <li>Factura actual de HOA de todas las propiedades (si aplica)</li>
                                        </ul> </td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3">(Tip: Sí los ingresos de la renta son necesarios para que califiques al préstamo, incluir 6 meses de los fondos de reserva de la propiedad sería una ventaja)</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <small>*No requerido, en caso de un programa de refinanciamiento streamline.</small>
                        </div>
                    </Col>
                </Row>
            </Container>              
        </div>
    )
}
