import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';

import Toolbar from './Toolbar/Toolbar';
import SideDrawer from './SideDrawer/SideDrawer';
import Backdrop from './Backdrop/Backdrop';

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

const targetElement = document.querySelector("sideDrawerItems");

class Navigationbar extends Component { 
    	state = {
		sideDrawerOpen: false
	};

	componentDidMount = () => {
		window.addEventListener('resize', this.checkAndAutoHideMobileNavbar)
	}

	UNSAFE_componentWillMount = () => {
		window.removeEventListener('resize', this.checkAndAutoHideMobileNavbar)
	}
	
drawerToggleClickHandler = () => {
	this.setState((prevState) =>{
		return{sideDrawerOpen: !prevState.sideDrawerOpen};
	});
	disableBodyScroll(targetElement);
};

backdropClickHandler = () => {
	this.setState({sideDrawerOpen: false});
	enableBodyScroll(targetElement);
};

checkAndAutoHideMobileNavbar = () => {
	const screenWidth = window.innerWidth

	if (this.state.sideDrawerOpen && screenWidth > 768) {
		this.setState({sideDrawerOpen: false});
	}
}

    render() {
        let backdrop;

		if (this.state.sideDrawerOpen) {
			backdrop = <Backdrop click={this.backdropClickHandler} />
		}
        return (
            <div style={{height: '100%'}}>
				<Toolbar drawerClickHandler={this.drawerToggleClickHandler} setModalShow={this.props.setModalShow} />
				<SideDrawer show={this.state.sideDrawerOpen} click={this.backdropClickHandler} setModalShow={this.props.setModalShow} />
				{backdrop}
			</div>
        )
        }
    }


export default withRouter(Navigationbar);