import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledHero from '../components/StyledHero'
import MapImg from '../images/sitemap.jpg';
import Banner from '../components/Banner';
import { Link, withRouter } from 'react-router-dom';
import { genericHashLink } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

const MyHashLink = (props) => genericHashLink(props, Link);

function Sitemap(props) {

    return (
        <div id="sitemapWrapper">
            <Helmet>
                <title>Sitemap</title>
                <meta name="description" content="Sun West is your one-stop-shop to buy a home or refinance a property, offering a complete line of mortgage products, including Conventional, FHA, VA, Home Improvements and Reverse mortgages." />
            </Helmet>
            <StyledHero img={MapImg}>
                <Banner title="Sitemap">
                </Banner>
            </StyledHero>
            <Container>
                <Row>
                    <Col className="p-5" lg={12}>                                       <section className="pricing-table">
                        <Container>
                            <Row>
                                <Col sm={12} md={6} lg={4} className="mb-3">
                                    <h3><Link to="./"><b>HOME</b></Link></h3>
                                    <h3><b><Link to="./About">Sobre Nosotros</Link></b></h3>
                                    <h3><b>Préstamos</b></h3>
                                    <p><Link to="./Conventional">Convencionales</Link></p>
                                    <p><Link to="./Fha">FHA</Link></p>
                                    <p><Link to="./Renovation">Mejoras al Hogar</Link></p>
                                    <p><Link to="./Va">VA</Link></p>
                                    <p><Link to="./Reverse">Hipoteca Reverse</Link></p>
                                </Col>
                                <Col sm={12} md={6} lg={4} className="mb-3">
                                    <h3><b>Recursos</b></h3>
                                    <p><Link to="./Buying">Compra Una Casa</Link></p>
                                    <p><Link to="./Refinancing">Refinancia Tu Propiedad</Link></p>
                                    <p><Link to="./Calculators">Calculadoras</Link></p>
                                    <p><Link to="./Documents">Documentos</Link></p><br />
                                    <h3><b><Link to="./Team">Equipo</Link></b></h3>
                                    <h3><b>Contáctanos</b></h3>
                                    <p><Link to="./Faq">Preguntas Frecuentes</Link></p>
                                    <p><MyHashLink smooth to="./#contactUsSpan">Contáctanos</MyHashLink></p>
                                    <p><a onClick={() => {
                                        props.setModalShow(true);
                                    }}>Solicita Ahora</a></p>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <h3><b>Páginas Adicionales</b></h3>
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.swmc.com/privacy-policy.php">Política de Privacidad</a></p>
                                    <p><Link to="./Terms">Términos y Condiciones</Link></p>
                                    <p><Link to="./disclaimer">Licencias</Link></p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default withRouter(Sitemap);
