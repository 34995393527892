import React from "react";
import { useLocation  } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./Footer.css";
import SubFooter from "../components/SubFooter";
// import { Link } from "react-router-dom";

export default function Footer() {

  const location = useLocation();
  return (
    <>
      <SubFooter />
      <footer className='text-center main-footer'>
        <div className='text-center'>
          <a
            title='Like us on Facebook!'
            href='https://www.facebook.com/SunWestMortgage'
            target='_blank'
            rel='noopener noreferrer'>
            <Image
              src='./assest/facebook.png'
              alt='Facebook Like Icon'
              style={{ height: "35px" }}
            />
          </a>
          &nbsp;
          <a
            title='Follow us on Twitter!'
            href='https://twitter.com/SunWestMortgage'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src='./assest/twitter_gray.png'
              alt='Twitter Icon'
              style={{ height: "35px" }}
            />
          </a>
          &nbsp;
          <a
            title='Follow us on LinkedIn!'
            href='https://www.linkedin.com/in/swmc1980'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src='./assest/linked_in_gray.png'
              alt='LinkedIn Icon'
              style={{ height: "35px" }}
            />
          </a>
          &nbsp;
        </div>
        &nbsp;
        <p>
          &copy;{new Date().getFullYear()}&nbsp;Sun West Mortgage Company, Inc.
          All Rights Reserved <br />
          <strong>
          For licensing information, go to: <a href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/company/3277" title="NMLS Consumer Access" target="_blank" rel="noreferrer">www.nmlsconsumeraccess.org</a>.<br/>
          Visit <a href="https://www.swmc.com/disclaimer" title="Click Here for full list of license information of Sun West Mortgage Company, Inc." target="_blank" rel="noreferrer">www.swmc.com/disclaimer</a> for the full list of license information.
          </strong><br/>
          
          Please&nbsp;
          <a
            href='https://www.swmc.com/TXdis.php'
            target='_blank'
            rel='noopener noreferrer'>
            Click Here&nbsp;
          </a>
          to view Texas Complaint Notice and Servicing Disclosure.
        </p>
        {location.pathname == "/Fha" ? <p>On an FHA 30-Year fixed rate purchase with loan amount $337,277.00 (at 96.5% LTV with 3.5% cash required from borrower), at an interest rate 3.375% with $0 discount points (Annual Percentage Rate - 4.4077%), you will be required to make 360 equal monthly payment of $1,491.09 (which includes principal and interest only, so your actual payment, including taxes, insurance, and other property charges, will be higher).</p> : null}

        {location.pathname == "/Documents" ? <p>The housing and residential financing offered herein is open to all without regard to race, color, religion, gender, sexual orientation, handicap, familial status, or national origin.</p> : null}

        {location.pathname == "/Renovation" ? <p>On a Conventional Homestyle Renovation 30-Year fixed rate purchase of second home with loan amount $65,250.00 (at 90% LTV with 10% cash from borrower), at an interest rate 3.50% with $0 discount points (Annual Percentage Rate - 3.9910%), you will be required to make 360 equal monthly payment of $293.00 (which includes principal and interest only, so your actual payment, including taxes, insurance, and other property charges, will be higher).</p> : null}

        {location.pathname == "/Va" || location.pathname == "/Reverse" || location.pathname == "/Calculators" || location.pathname == "/Team" ? <p>The housing and residential financing offered herein is open to all without regard to race, color, religion, gender, sexual orientation, handicap, familial status, or national origin. Our products and services have no affiliation with or endorsement from any government agency or body.</p> : null}

        {location.pathname == "/Fha" || location.pathname == "/Conventional" || location.pathname == "/Renovation" || location.pathname == "/Reverse" ? <p>Our products and services have no affiliation with or endorsement from any government agency or body.</p> : null}
        <p>
          <strong>
            Although Sun West is approved to conduct business in the
            state of New York, this website has not yet been approved by the
            State of New York Department of Financial Services. <br/>For properties
            located in the State of New York, this website can not be used to
            upload an application, please visit{" "}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.swmc.com'>
              SWMC.com
            </a>{" "}
            to upload an application.
          </strong>
        </p>
        <br />
        <Container>
          <Row className='justify-content-md-center'>
            <Col lg={4}>
              <img
                id='equalImg'
                alt='EHO logo'
                src='./assest/eho_blue.png'
                title='Equal Housing Opportunity'
                style={{ width: "70px" }}
              />
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
