import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import StyledHero from '../components/StyledHero'
import FaqImg from '../images/faq.jpg';
import Banner from '../components/Banner';
import { FiCheckCircle } from 'react-icons/fi';
import {Helmet} from "react-helmet";

import './FAQ.css';



export default function FAQ() {


    return (
        <div id="faqWrapper">
            <Helmet>
                <title>Preguntas Frecuentes</title>
                <meta name="description" content="LowRates US, Sun West documents FAQ." />
            </Helmet>
            <StyledHero img={FaqImg}>
                <Banner>
                </Banner>
            </StyledHero>            
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="p-4 p-sm-5 rounded">
                    <div className="product-tabs">
                        <Row>
                            <Col lg={12}> 
                                <div className="section-title mb30">
                                    <h1>Preguntas Frecuentes</h1>
                                    <p>Muchas personas interesadas en obtener una hipoteca, frecuentemente se topan con preguntas durante el proceso que tal vez no entienden por completo. Esta sección de preguntas y respuestas fue creada para contestar algunas de las preguntas y conceptos más comunes que probablemente verás aprendiendo sobre las hipotecas.</p>
                                </div>
                            </Col>
                            <Col className="faqAccordion" lg={12}> 
                                <Accordion>
                                    <Card>
                                        <Accordion.Collapse eventKey="0" className="faq-answer">
                                        <Card.Body>Sun West no cobra una tarifa por solicitar una hipoteca.</Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" className="faq-question">
                                        ¿Hay que pagar algo para solicitar una hipoteca?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="1" className="faq-answer">
                                        <Card.Body>Sun West tiene uno de los tiempos de cierre más rápidos en la industria, con cierres a 20 días; pero cada situación es diferente, y debido a requisitos de documentación y distintos tiempos de respuesta de los prestatarios, el tiempo promedio para cerrar un préstamo puede variar.</Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="1" className="faq-question">
                                        ¿Cuánto tiempo tomará cerrar mi préstamo?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="2" className="faq-answer">
                                        <Card.Body>Un refinanciamiento hipotecario significa obtener un nuevo préstamo para bajar el pago hipotecario, convertir tu préstamo actual en uno más asequible o manejable, u obtener dinero en efectivo en equidades disponible de tu hogar.</Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="2" className="faq-question">
                                        ¿Qué es refinanciamiento?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="3" className="faq-answer">
                                        <Card.Body>Sun West recomienda verificar con tu prestador si hay penalidades. Puede ser que haya tarifas cuando refinancies tu casa, aunque podrías tener la opción de incluir estos costos a tu nuevo préstamo.</Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="3" className="faq-question">
                                        ¿Hay tarifas implicadas en refinanciamiento?
                                        </Accordion.Toggle>   
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="4" className="faq-answer">
                                        <Card.Body>
                                            <p>La mayoría de las hipotecas requieren un mínimo de pronto, excepto los préstamos VA y Rural. También puedes ser elegible para un programa de asistencia, que ayuda a bajar el mínimo de pronto necesario en algunos préstamos.</p>
                                            <p>En hipotecas Convencionales, tu prestador requerirá que pagues un seguro hipotecario privado (PMI, por sus siglas en inglés) como parte de tus pagos mensuales, si tu pronto es menor del 20% porciento del precio de la propiedad.</p>
                                            <p>También existen préstamos gubernamentales con requisitos de pronto más bajos, como el préstamo FHA, que solo requiere un 3.5% porciento de pronto. Pero de nuevo, estos programas también requieren que abones una prima del seguro hipotecario.</p>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="4" className="faq-question">
                                        ¿Cuánto debo dar de pronto para obtener una hipoteca?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="5" className="faq-answer">
                                        <Card.Body>Los seguros hipotecarios fueron establecidos por prestatarios y agencias gubernamentales para proteger a los inversionistas antes de que se salde la hipoteca. Cuando un prestatario no cumple con los pagos de su préstamo y la propiedad se tiene que vender a través de una ejecución hipotecaria, la propiedad puede ser vendida a un valor menor al original. Los seguros hipotecarios, en estos casos, le permiten al inversionista reclamarle a la aseguradora una porción parcial o completa de la cantidad perdida.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="5" className="faq-question">
                                        ¿Por qué es necesario tener un seguro hipotecario?
                                        </Accordion.Toggle>   
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="6" className="faq-answer">
                                        <Card.Body>La relación préstamo-valor (en inglés, Loan-to-Value o LTV) es un porcentaje del total del valor de tasación de tu propiedad o su precio de compra, el que sea más bajo. El préstamo a valor es uno de los principales factores de riesgo utilizados por los bancos hipotecarios para determinar la calificación del prestatario a la hipoteca. Entre más alto el LTV, mayor es el riesgo para el prestador y menos las posibilidades del prestatario a calificar para el préstamo.                     
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="6" className="faq-question">
                                        ¿Qué es LTV?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="7" className="faq-answer">
                                        <Card.Body><p>Tu tasa de interés hipotecaria depende de un sinnúmero de variables, que incluyen pero no se limitan a la puntuación de crédito, cantidad del pronto, propósitos del préstamo, ocupación, la relación préstamo-valor y relación deuda-ingreso. Cada préstamo hipotecario tiene sus requisitos que pueden, de forma indirecta, afectar tu tasa de interés.</p>
                                        <p>La base de interés en el mercado es determinada por el mercado secundario a través de bonos del tesoro estadounidense, cuyo precio es establecido por las demandas del mercado. Todos estos factores combinados van a determinar qué tasa de interés obtendrás para tu hipoteca. Cuando estés listo para asegurar tu tasa de interés durante tu solicitud hipotecaria, te recomendamos contactar a tu oficial de préstamos para hablar sobre el proceso de &quot;asegurar&quot; o &quot;Lock In&quot;.</p>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="7" className="faq-question">
                                        ¿Qué determina mi tasa de interés hipotecaria?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="8" className="faq-answer">
                                        <Card.Body>Los gastos de cierre son los gastos asociados con adquirir un préstamo hipotecario. Estos incluyen pero no se limitan a:
                                        <ol className="listnone bullet">
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Cuota del prestador</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Cargos de Originación</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Puntos de descuento de préstamo</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Costos de terceros para la tasación, reporte de crédito, certificación de inundaciones, etc.</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Título y fideicomiso/Cargos del abogado</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Transferencias/Intangibles/Impuestos hipotecarios exigidos por la ciudad, estado, etc.</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Intereses per diem</li>
                                        </ol>
                                        <p>La ley le exige al prestador proveerte un estimado preliminar una vez sometas tu aplicación. </p>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="8" className="faq-question">
                                        ¿Cuáles son los gastos de cierre?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="9" className="faq-answer">
                                        <Card.Body>Los cargos por Originación incluyen costos por servicios de solicitud del préstamo, preparación, suscripción y procesamiento. El cargo por Originación cubre el pago al prestador y/o corredor de bienes raíces por proveerte un servicio al hacer el préstamo.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="9" className="faq-question">
                                        ¿Qué son los cargos por Originación?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="10" className="faq-answer">
                                        <Card.Body>Este proceso se conoce formalmente como fijación de tasa de interés o &quot;Lock In&quot;. Puedes asegurar la tasa de interés de tu préstamo una vez sometas tu solicitud. Si no deseas asegurar la tasa de interés al momento de solicitar, tienes la opción de pedirle a tu oficial de préstamos que verifique regularmente la tasa de interés y asegurarla cuando te sea más conveniente. Pero debes tener en cuenta que hasta que no asegures tu tasa de interés, la misma es sujeta a fluctuaciones del mercado y puede seguir aumentando. <br/>El &quot;Lock In&quot; de la tasa de interés debe estar vigente hasta la fecha en que financien tu préstamo hipotecario.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="10" className="faq-question">
                                        ¿Qué es &quot;Lock In&quot; de la tasa de interés?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="11" className="faq-answer">
                                        <Card.Body>Tu prestador va a requerir una tasación para determinar profesionalmente el valor de la propiedad que deseas comprar. Un tasador certificado será escogido para inspeccionar la casa, la calidad de la construcción, edad, etc. El tasador entonces comparará el precio pedido por la propiedad a data reciente de ventas de propiedades en la región, para ver si el valor es justo. <br />Un prestador no aprobará un préstamo en donde el precio de compra es mayor al valor tasado, a menos de que tú aceptes incluir la diferencia en tus costos de cierre.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="11" className="faq-question">
                                        ¿Qué es una tasación?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="12" className="faq-answer">
                                        <Card.Body>Como una tasación se utiliza primordialmente para que el prestador sepa que no están aprobando un préstamo que excede el valor de la propiedad, los prestadores aún te aprobarán un préstamo por el más bajo de ambos.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="12" className="faq-question">
                                        ¿Y si el valor tasado de la propiedad que seleccioné es mayor al del precio de venta?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="13" className="faq-answer">
                                        <Card.Body>
                                            <p>Hay algunos tipos de seguros requeridos cuando vas a obtener tu hipoteca y cada uno tiene un propósito diferente.</p>
                                                <ol>
                                                    <li>Seguro Hipotecario Privado (PMI, por sus siglas en inglés): Tu prestador te pedirá que te acojas a este seguro hipotecario si tu pronto es menos del 20% porciento de tu monto del préstamo. El propósito de este seguro es proteger la inversión del prestador. Es generalmente pagada de tu fideicomiso mensualmente y es reunida con tu pago hipotecario mensual.</li>
                                                    <li>Seguro Hipotecario FHA: Este préstamo hipotecario sirve el mismo propósito que el PMI pero tiene un nombre diferente porque es cobrado por tu prestador por sacar un préstamo gubernamental FHA cuando no cumples con lo requerimientos LTV. Necesitarás pagar una porción de la prima durante el cierre, en adición a un cargo mensual.</li>
                                                    <li>Seguro Contra Riesgos: Es un seguro de propiedad requerido por el prestador para proteger su inversión contra daños a la propiedad antes de que el préstamo se salde. Ten en cuenta que un Seguro Contra Inundaciones también puede ser requerido en ciertas áreas, adicional a el Seguro Contra Riesgo.</li>
                                                    <li>Seguro de Título de Propiedad: Como sugiere el nombre, este es un seguro de indemnización adquirido para proteger el título de la propiedad. Este seguro protege al dueño del hogar y al prestador de defectos en el título y posibles demandas en su contra por invalidez en los documentos o reclamaciones. </li>
                                                </ol>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="13" className="faq-question">
                                        ¿Cuáles son los diferentes tipos de seguros requeridos al obtener una hipoteca?
                                        </Accordion.Toggle> 
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="14" className="faq-answer">
                                        <Card.Body>Bajo el contexto hipotecario, &quot;equity&quot; se refiere a la diferencia entre el valor de la propiedad en el mercado y la cantidad que actualmente se le debe a la hipoteca. Es básicamente la cantidad de dinero con la que el propietario se podrá quedar después de vender la casa o después de saldar el balance pendiente del préstamo hipotecario.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="14" className="faq-question">
                                        ¿Qué significa &quot;equity&quot;?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="15" className="faq-answer">
                                        <Card.Body>
                                            <p>El término fideicomiso puede referirse a dos cosas: el proceso de fideicomiso o una cuenta de fideicomiso. El proceso de fideicomiso es una parte importante de la Originación de la hipoteca en donde una compañía tercera es asignada para mantener control de todos los fondos y documentos de todas las partes (vendedor, comprador y prestador) durante la transacción. Los fondos son enviados a la compañía de fideicomiso para ser procesados y verificados, y la compañía de fideicomiso, luego del desembolso, prepara una declaración de cierre resumiendo la distribución de fondos y los términos de tu hipoteca.</p>
                                            <p>Una cuenta de fideicomiso usualmente se refiere a una cuenta hipotecaria abierta por el prestador para guardar fondos que se utilizarán para pagar los impuestos de la propiedad y el seguro, incluyendo el seguro hipotecario. Los pagos hechos de una cuenta de fideicomiso se reflejarán en los estados mensuales de tu hipoteca, así no te tienes que preocupar de manejar estos pagos por separado. </p>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="15" className="faq-question">
                                        ¿Qué es fideicomiso?
                                        </Accordion.Toggle>
                                    </Card>
                                </Accordion>                                              
                            </Col>
                        </Row>
                        </div>
                     </div>
                </div>
            </Container>              
        </div>
    )
}
