import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledHero from '../components/StyledHero'
import genImg from '../images/kitchen.jpg';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { genericHashLink } from 'react-router-hash-link';
import axios from 'axios';
import parser from 'html-react-parser';


const MyHashLink = (props) => genericHashLink(props, Link);

export default function Terms() {

    const [termsAndConditionsData, setTermsAndConditionsData] = useState();

    useEffect(() => {
        const fetchTermsAndConditions = async () => {
            try {
                await axios
                    .post(process.env.REACT_APP_TERMS_AND_CONDITIONS, { websiteName: 'swmc.com' })
                    .then((response) => {
                        // console.log(JSON.stringify(response.data.data));
                        setTermsAndConditionsData(response.data.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        };

        fetchTermsAndConditions();
    }, []);

    return (
        <div id="termsWrapper">
            <Helmet>
                <title>Términos y Condiciones</title>
                <meta name="description" content="Sun West reserves the right at its discretion to change these TERMS AND CONDITIONS OF USE at any time without notice. Your continued use of this website after the change of TERMS AND CONDITIONS OF USE shall constitute your acceptance to agree to any such changes." />
            </Helmet>
            <StyledHero img={genImg}>
                <Banner title="Términos y Condiciones">
                </Banner>
            </StyledHero>
            <Container>
                <Row className="mt-4">
                    {termsAndConditionsData ?
                        <Col lg={12} md={12} className="px-5 py-0 text-center">
                            {termsAndConditionsData.angelaidisclaimer.map((angelaidisclaimer, i) => (
                                <p key={i} style={{ fontSize: "18px", lineHeight: "1.4" }}>
                                    {parser(angelaidisclaimer)}
                                </p>
                            ))}
                        </Col>
                        : ""}
                    <Col className="p-5 text-center" lg={12}>
                        <div style={{ fontSize: "18px", lineHeight: "1.4" }}>
                            <p>Please read these TERMS AND CONDITIONS OF USE carefully.     By  using LowRatesPR.com website or a related website (the &quot;Site&quot;) belonging to Sun West Mortgage Company, Inc. (“Sun West”) or one of its subsidiaries, you hereby agree to these TERMS AND CONDITIONS OF USE. If you do not agree to these TERMS AND CONDITIONS OF USE, please do not use this site.</p>
                            <p>Sun West reserves the right at its discretion to change these TERMS AND CONDITIONS OF USE at any time without notice. Your continued use of this website after the change of TERMS AND CONDITIONS OF USE shall constitute your acceptance to agree to any such changes.</p>
                            <hr />
                        </div>
                        <br />
                        <div style={{ fontSize: "14px" }}>
                            <ul className="pl-4 text-justify">
                                <li><strong>COPYRIGHT</strong>: All information displayed, transmitted or carried on the Site (including, but not limited to, text, photographs, news articles, opinions, reviews, trademarks, service marks and the like, collectively the “Content”) is protected by international copyright and other intellectual property laws. The Content is owned by Sun West, its affiliates or third party licensors. Any use of the Content, including but not limited to, modification, distribution, reproduction, republication, transmission, posting, reposting, transfer, uploading, duplication or selling without the prior written permission of Sun West is strictly prohibited. You may make a single copy of the Content displayed on the Site for your personal, informational, noncommercial offline use only, provided the Content is not modified in any way and all copyright and other notice on any copy are retained. The use of any such Content on any other website or network computer environment is prohibited.</li>
                                <li><strong>DISCLAIMER</strong>: Sun West disclaims all warranties, express or implied, including but not limited to, implied warranties of merchantability or fitness for a particular purpose. Sun West does not represent or warrant that materials on the Site are accurate, complete, reliable, current, or error-free. Sun West does not represent or warrant that the Site or its server(s) are free of viruses or other harmful components.</li>
                                <li><strong>LIMITATION OF LIABILITY</strong>: In no event shall Sun West or its affiliates, licensors, employees, agents or contractors be liable to you for any damages or losses, including but not limited to, direct, indirect, special, consequential, incidental or punitive damages resulting from or caused by the Site, its public posting web boards, its content or any errors or omissions in its content, even if advised of possibility of such damages.</li>
                                <li><strong>HYPERLINKS</strong>: As a service to users of the Site, we include links to other sites and Sun West has no control over and makes no representations of any kind regarding the content or appropriateness of content on such sites, and you hereby irrevocably waive any claim against Sun West with respect to such sites.</li>
                                <li><strong>SUBMISSIONS</strong>: You acknowledge that by submitting messages or materials, including but not limited to, posting or email, to Sun West, you automatically grant Sun West a perpetual, royalty-free, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from and distribute such materials or incorporate such materials into any form.</li>
                                <li><strong>TERMINATION</strong>: Sun West may, in its sole discretion, terminate, cancel or suspend your right to access to all or part of the service at the Site for any reason, at any time without notice. Upon termination, you must destroy all materials obtained from the Site, and all copies thereof, whether made under this TERMS AND CONDITIONS OF USE or otherwise.</li>
                                <li><strong>MISCELLANEOUS</strong>: 1. Sun West reserves the right to change, remove or modify any and all content contained on the Site at any time without notice. 2. You agree to report any copyright violations of the TERMS AND CONDITIONS OF USE to Sun West as soon as you become aware of them. In the event you have a claim of copyright infringement with respect to material that is contained in the Site, please notify us. (<MyHashLink smooth to="./#contactUsSpan">Contact Us</MyHashLink>)</li>
                                <li><strong>AUTHORITY</strong>: The only persons authorized to enter into contracts on behalf of Sun West Mortgage Company, Inc. are the company&#39;s President and Chief Executive Officer. You may call our corporate office at <a href="tel:+18004537884">+1 (800) 453-7884</a> or e-mail <a href="mailto:contracts@swmc.com">contracts@swmc.com</a> to verify the execution of any contract.</li>
                                <li><strong>RECORDING</strong>: Phone calls between you and any representatives of Sun West Mortgage, Inc. shall be recorded and monitored for quality assurance and training purposes.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
