import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledHero from '../components/StyledHero'
import RenoImg from '../images/reno.jpeg';
import Banner from '../components/Banner';
import { FiCheckCircle } from 'react-icons/fi';
import { GoLinkExternal } from 'react-icons/go'
import { MdLocalPhone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";




export default function Renovation(props) {

    return (
        <div id="renoWrapper">
            <Helmet>
                <title>HomeStyle&reg; Renovation</title>
                <meta name="description" content="Sun West is the only mortgage bank in Puerto Rico that offers the HomeStyle® Renovation mortgage." />
            </Helmet>
            <StyledHero img={RenoImg}>
                <Banner title="Home Style&reg; Renovation">
                </Banner>
            </StyledHero>
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <div className="product-tabs">
                            <Row>
                                <Col lg={12} className="nopadding">
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="overview">
                                            <h2>Sobre HomeStyle&reg; Renovation</h2>
                                            <p>Sun West es el único banco hipotecario en Puerto Rico que ofrece el préstamo HomeStyle&reg; Renovation, un préstamo respaldado por el Gobierno que le permite a prestatarios calificados añadir dinero adicional a un préstamo hipotecario o una hipoteca de refinanciamiento, para remodelar o hacer mejoras al hogar</p>
                                            <h3>Beneficios claves del programa HomeStyle&reg; Renovation</h3>
                                            <ul className="listnone bullet">
                                                <li><FiCheckCircle className="bullet-check-circle-default" />Está disponible como una hipoteca <Link to="./fixedmortgage">15 o 30 años con tasa fija</Link>, o como una hipoteca de precio ajustable.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />El pronto puede ser de solo 3% porciento y los prestatarios evitan gastos de cierre asociados con sacar un segundo préstamo hipotecario.</li>
                                            </ul>

                                            <p>Para una lista de los documentos requeridos para el cierre, por favor procede a: <Link to="./Documents">DOCUMENTOS&nbsp;<GoLinkExternal /></Link></p>
                                            <p>Nuestros profesionales hipotecarios con amplia experiencia y licencias en hipotecas están listos para contestar cualquier pregunta que puedas tener. Por favor llámanos al <a href="tel:+17877869378"><MdLocalPhone />&nbsp;787&#8209;SUN&#8209;WEST.</a></p>
                                            <div className="text-center">
                                                <a className="btn btn-primary btns" onClick={() => {
                                                    props.setModalShow(true);
                                                }}>Solicita Ahora</a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
