import React, { useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { genericHashLink } from 'react-router-hash-link';
import './Home.css';
import Contact from '../components/PostForm';
import IvanVideo from '../components/Video';
import NONCImg from '../images/SWM-21-0678_Edrop_Referral_Program-02.png';
import { Helmet } from "react-helmet";
import GenerateLead from "../components/GenerateLead";

const MyHashLink = (props) => genericHashLink(props, Link);

const Home = (props) => {
    const [showNOCModal, setshowNOCModal] = useState(false);
    const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

    return (
        <div>
            <Helmet>
                <title>Purchase, Refinance @Sun West Mortgage Company Inc.</title>
                <meta name="description" content="LowRates is a DBA of Sun West Mortgage Company Inc. Looking to buy a home or refinance your property? Sun West offers comprehensive services and a variety of mortgage options, including Conventional, FHA, VA, and Reverse Mortgages. Contact us today for all your mortgage related queries." />
            </Helmet>
            <section id="home" className="nexa-banner-business">
                <div id="carousel-banner" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner" role="listbox">
                        <div className="item item1">
                            <div className="banner-img">
                                <Image src="./assest/ivanR_glove.png" srcSet="./assest/ivanR_glove.png 634w,
                            ./assest/ivanR_glove-sm.png 300w" alt="Ivan Rodriguez posing with a baseball globe and ball." className="clip-css-banner float-right" />
                                <div className="overlay-banner"></div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-shape">
                        <Image src="./assest/container.png" className="img-fluid" alt="Bottom white shape" />
                    </div>
                </div>
                <Container>
                    <Row className="banner-inner-info">
                        <Col md={12}>
                            <div className="banner-title">
                                <h4>Es Hora de Llegar a Casa</h4>
                                <h2>Comience hoy completando la APLICACIÓN</h2>
                                <div className="btn-video">
                                    <a href="https://lowratespr.com/truapproval/" target="_blank" rel="noopener noreferrer" className="btn btn-success">Conozca más Sobre TRU Approval</a>
                                    <MyHashLink className="btn btn-success" smooth to="./#contactUsSpan">Tru Approval</MyHashLink>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="banner-inner-info2 d-block d-sm-block d-md-none">
                        <Col>
                            <a href="https://lowratespr.com/truapproval/" target="_blank" rel="noopener noreferrer" className="btn btn-TruAp">Conozca más Sobre TRU Approval</a>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="nexa-portfolio-business" id="messageBox">
                <Container>
                    <Row>
                        <Col md={12} className="text-center mt-3">
                            <p>Bienvenido a <span>Sun West Mortgage Company</span>, un banco hipotecario ágil y amigable con operaciones en <br />48 estados, DC, Puerto Rico y las Islas Vírgenes de los Estados Unidos. Sun West te ofrece un servicio integral para comprar un hogar o refinanciar una propiedad, con una amplia gama de productos hipotecarios, incluyendo préstamos<br /><Link to="./Conventional">Convencionales</Link>, <Link to="./Fha">FHA</Link>, <Link to="./Va">VA</Link>, <Link to="./Renovation">Mejoras al Hogar</Link> y <Link to="./Reverse">Reverse Mortgages</Link>.</p><br />
                            <p>Comienza hoy, llenando el formulario en <button
                                onClick={() => {
                                    props.setModalShow(true);
                                }}
                            >
                                SOLICITA
                            </button> o llamando a uno de nuestros Oficiales de Préstamos a <a href="tel:+17877869378">787-SUN-WEST.</a></p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <br /><br />
            <section className="nexa-portfolio-business">
                <div className="text-center" id="imgBox">
                    <Image src="./assest/bluehouse.png" alt="Blue House" />
                </div>
            </section>

            <section className="nexa-portfolio-business" id="btnsSection">
                <div id="carousel-portfolio" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner" role="listbox">
                        <div className="item active">
                            <Container>
                                <Row>
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="portfolio-details">
                                            <Link to="./Buying">
                                                <h2>Compra Un Hogar</h2>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="portfolio-details">
                                            <Link to="./Refinancing">
                                                <h2 >Refinancia Tu Propiedad</h2>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="portfolio-details">
                                            <button
                                                onClick={() => {
                                                    props.setModalShow(true);
                                                }}
                                            >
                                                <h2>Solicita Ahora</h2>
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3}>
                                        <div className="portfolio-details">
                                            <a href="https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusLogin.jsf?loginByUsingView=LOAN" target="_blank" rel="noopener noreferrer">
                                                <h2 >See My Loan Status</h2>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>

            </section>
            <IvanVideo />
            <span id="contactUsSpan"></span>
            <Contact />
        </div>
    )

}
export default Home;
