import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import StyledHero from '../components/StyledHero'
import BuyImg from '../images/buy.jpeg';
import Banner from '../components/Banner';
import { FiCheckCircle } from 'react-icons/fi';
import { GoLinkExternal } from 'react-icons/go'
import { MdLocalPhone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";




export default function Buying(props) {

    return (
        <div id="buyingWrapper">
            <Helmet>
                <title>Comprar Una Casa</title>
                <meta name="description" content="Sun West Mortgage Company, Inc. can help you get started, and we have a complete line of financing options so you may find a mortgage that best suits you." />
            </Helmet>
            <StyledHero img={BuyImg}>
                <Banner title="Comprar Una Casa">
                </Banner>
            </StyledHero>
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <div className="product-tabs">
                            <Row>
                                <Col lg={12}>
                                    <div className="lender-details">
                                        <div className="lender-head-sections">
                                            <Row>
                                                <Col sm={12} md={3}>
                                                    <div className="lender-img">
                                                        <Image src="./assest/Logo-small.png" className="img-fluid" alt="Sun West Mortgage Company, Inc. logo" />
                                                    </div>
                                                </Col>
                                                <Col className="mb-3" sm={12} md={7}>
                                                    <h2 className="lender-name">Sun West Mortgage Company</h2>
                                                    <p>En Sun West Mortgage Company, Inc., entendemos que las necesidades de cada persona son diferentes. Sea que quieres comprar un hogar o refinanciar tu préstamo hipotecario, puedes confiar en que nuestro equipo informado y amigable te asistirá para encontrar el préstamo perfecto para tu hogar, entre nuestra línea completa de productos hipotecarios.</p>
                                                </Col>
                                                <Col sm={12} className="text-center">
                                                    <a className="btn btn-sm rounded box-shadow btn-primary apply_btn_color"
                                                        onClick={() => {
                                                            props.setModalShow(true);
                                                        }}>Solicita Ahora</a>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* The below section with be hidden for now until the spanish version is completed*/}
                                        <div className="mb30 d-none">
                                            <div className="bg-sun pinside20 borderTop">
                                                <h3 className="mb0 text-white text-center">Conventionales (Uno de Nuestros Programas)</h3>
                                            </div>
                                            <div className="outline pinside20 box-shadow borderBottom">
                                                <Row>
                                                    <Col sm={6} md={4} lg={3}>
                                                        <div className="text-center">
                                                            <h3 className="rate">5.000% Rate</h3>
                                                            <h3 className="rate">5.240% APR</h3>
                                                            <small>10-Year Fixed</small>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} md={4} lg={3}>
                                                        <div className="text-center">
                                                            <h3 className="fees">4.875% Rates</h3>
                                                            <h3 className="fees">5.115% APR</h3>
                                                            <small>15-Year Fixed</small>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} md={4} lg={3}>
                                                        <div className="text-center">
                                                            <h3 className="compare-rate">5.125% Rates</h3>
                                                            <h3 className="compare-rate">5.365% APR</h3>
                                                            <small>30-Year Fixed</small>
                                                        </div>
                                                    </Col>
                                                    <Col className="text-center p-0" sm={12} md={12} lg={3}>
                                                        <div className="compare-action"> <a href="./rates" className="btn btn-primary btn-sm rounded box-shadow p-3">Vea Los Programas</a> </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="lender-loan-sections mb-4">
                                            <Row>
                                                <Col sm={12}>
                                                    <h2>Razones para Comprar</h2>
                                                    <ul className="listnone bullet">
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />Ser propietario no solo te permite invertir en un hogar, sino que también te provee un ambiente estable para tener una familia.</li>
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />Entre más temprano comiences a ser propietario, más temprano comienzas a crear valor (&quot;equity&quot;).</li>
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />Recibes beneficios de impuestos y a la vez estableces un historial de crédito.</li>
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />Podrías considerar comprar una vivienda como una propiedad de inversión para rentar y recibir ingresos adicionales.</li>
                                                    </ul>
                                                    <p>Para ver las preguntas más frecuentes, por favor procede a:  <Link to="./Faq">PREGUNTAS FRECUENTES<GoLinkExternal /></Link></p>
                                                    <p>Nuestros profesionales hipotecarios con amplia experiencia y licencias en hipotecas están listos para contestar cualquier pregunta que puedas tener. Por favor llámanos al <a href="tel:+17877869378"><MdLocalPhone />&nbsp;787&#8209;SUN&#8209;WEST.</a></p>
                                                    <div className="text-center">
                                                        <a className="btn btn-primary btns"
                                                            onClick={() => {
                                                                props.setModalShow(true);
                                                            }}>Solicita Ahora</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
